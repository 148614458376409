import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';

import { registerLocaleData } from '@angular/common';
import localeCs from '@angular/common/locales/cs';

import requestedLocale from '../locale';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { FooterComponent } from '../components/footer/footer.component';
import { HeaderComponent } from '../components/header/header.component';

import { IndexPageModule } from '../routes/index/index-page.module';
import { GdprOutagePageModule } from '../routes/gdpr-outage/gdpr-outage-page.module';

import { StoreModule } from 'store/store.module';

registerLocaleData(localeCs, 'cs');

@NgModule({
    declarations: [
        AppComponent,
        FooterComponent,
        HeaderComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        StoreModule,

        IndexPageModule,
        GdprOutagePageModule,
    ],
    providers: [
        { provide: LOCALE_ID, useValue: requestedLocale() },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(
    ) {
    }
}
