import * as firebase from 'firebase';
import { get } from '../json/get';

export const LOADING = '@endorphin:firebase:database:loading';
export const LOADED = '@endorphin:firebase:database:loaded';

export function loading(path: string[]) {
    return {
        type: LOADING,
        path
    };
}

export function loaded(path: string[], val: any) {
    return {
        type: LOADED,
        path,
        val
    };
}

export function sync(path: string[]) {
    return (dispatch, getState) => {
        dispatch(loading(path));
        firebase.database().ref(path.join('/')).on('value',  (doc) => {
            dispatch(loaded(path, doc.val()));
        });
    };
}
