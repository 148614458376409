import * as _ from 'lodash';

export function join(...parts: (string | null | undefined)[]): string {
    return _.join(_.compact(parts), '.');
}

export function alphaKeys(num ?: number) {
    num = num || 0;
    const power = num > 1 ? Math.trunc(Math.log(num - 1) / Math.log(26)) + 1 : 1;
    return function (idx: number): string {
        let res = '';
        for (let i = 0; i < power; ++i) {
            const curr = idx % 26;
            idx = Math.trunc(idx / 26);
            res = String.fromCharCode(curr + 97) + res;
        }
        return res;
    };
}

export function isInRange(rangeIdx: string, keyToTest: string): boolean {
    const pos = rangeIdx.indexOf('-');
    if (pos === -1) {
        return false;
    }
    const first = rangeIdx.substring(0, pos);
    const last = rangeIdx.substring(pos + 1);
    if (keyToTest < first || keyToTest > last) {
        return false;
    }
    return true;
}

export function createRange(first: string, last: string): string | null {
    const parentElem = parent(first);
    if (parentElem !== parent(last)) {
        return null;
    }
    let realFirst = leaf(first);
    let idx = realFirst.indexOf('-');
    if (idx !== -1) {
        realFirst = realFirst.substring(0, idx);
    }
    let realLast = leaf(last);
    idx = realLast.lastIndexOf('-');
    if (idx !== -1) {
        realLast = realLast.substring(idx + 1);
    }
    return join(parentElem, realFirst + '-' + realLast);
}

export function parent(path?: string | null): string {
    if (path == null) {
        return '';
    }
    const idx = path.lastIndexOf('.');
    return idx !== -1 ? path.substring(0, idx) : '';
}

export function leaf(path?: string | null): string {
    if (path == null) {
        return '';
    }
    const idx = path.lastIndexOf('.');
    return idx !== -1 ? path.substring(idx + 1) : path;
}

export function next(path?: string | null): string {
    if (path == null || path.length === 0) {
        return 'a';
    }
    const idx = path.lastIndexOf('.');
    const parentElem = idx !== -1 ? path.substring(0, idx) : '';
    const leafElem = idx !== -1 ? path.substring(idx + 1) : path;
    const tidx = idxOf(leafElem);
    const utils = alphaKeys(Math.pow(26, leafElem.length));
    return join(parentElem, utils(tidx + 1));
}

function idxOf(key: string): number {
    let result = 0;
    for (let pos = 0; pos < key.length; ++pos) {
        result = result * 26 + key.charCodeAt(pos) - 97;
    }
    return result;
}

