import { Component, Inject, LOCALE_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'ism-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.less']
})
export class HeaderComponent {
    user = null;

    constructor(
        @Inject(LOCALE_ID) public locale: string,
        private route: ActivatedRoute,
    ) {
    }
}
