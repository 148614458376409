export default function (): Promise<string> {
    return new Promise( (resolve) => {
        const client = new XMLHttpRequest();
        client.open('GET', '/assets/messages.xlf');
        client.onload = function () {
            resolve(client.responseText);
        };
        client.send();
    });
}
