import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IndexPageComponent } from './index-page.component';
import { IndexCalendarComponent } from './calendar.component';
import { IndexBreedComponent } from './breed.component';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        IndexPageComponent,
        IndexCalendarComponent,
        IndexBreedComponent,
    ],
})
export class IndexPageModule { }
