import { Component } from '@angular/core';
import { AppStore } from 'store/store';
import { Observable } from 'rxjs';
import * as DogSelectors from 'store/dog-selectors';
import { distinctUntilChanged } from '../../../node_modules/rxjs/operators';

@Component({
    selector: 'ism-index-page',
    templateUrl: './index-page.component.html',
    styleUrls: ['./index-page.component.less']
})
export class IndexPageComponent {
    dog$: Observable<Object>;
    constructor(
        private store: AppStore,
    ) {
       this.dog$ = this.store.select(DogSelectors.info2('4'));
    }
}
