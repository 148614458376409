import * as _ from 'lodash';
import * as DatabaseActions from './database-actions';
import { set } from '../json/set';

export default (state = { }, action: any = {}) => {
    let newState = state;
    switch (action.type) {
        case DatabaseActions.LOADING:
            newState = set(state, action.path, null);
            return newState;
        case DatabaseActions.LOADED:
            newState = set(state, action.path, action.val);
            return newState;
    }
    return newState;
};
