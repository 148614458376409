import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { onValue } from '@endorphin/firebase/database-selectors';

import * as DatabaseActions from '@endorphin/firebase/database-actions';

export function info<R>(store, id: string): Observable<R> {
    return onValue(store, ['dogs', id, 'info']);
}

export function info2<R>(id: string): ((state, dispatch) => R) {
    const path = ['dogs', id, 'info'];
    return (state, dispatch: (any)) => {
        const selectPath = path.slice();
        selectPath.unshift('database');
        const res = _.get(state, selectPath);
        if (res === undefined) {
            dispatch(DatabaseActions.sync(path));
            return null;
        }
        return res;
    };
}
