import { Type, TRANSLATIONS, TRANSLATIONS_FORMAT } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import * as xliff from '../xliff';

export async function bootstrap<M>(moduleType: Type<M>, lang?: string, defaultLang?: string) {
    if (lang !== defaultLang) {
        const xlf = await xliff.getOriginalXliff();
        const trans = await xliff.getTranslation(lang);
        const transXlf = xliff.applyTranslation(xliff.xliffToJson(xlf), trans, lang);
        const translations = xliff.jsonToXliff(transXlf);
        await platformBrowserDynamic().bootstrapModule(moduleType, {
            providers: [
                {provide: TRANSLATIONS, useValue: translations},
                {provide: TRANSLATIONS_FORMAT, useValue: 'xlf'}
            ]
        }).catch(err => console.log(err));
    } else {
        await platformBrowserDynamic().bootstrapModule(moduleType).catch(err => console.log(err));
    }
}
