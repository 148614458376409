import * as _ from 'lodash';

import { enableProdMode } from '@angular/core';
import { bootstrap } from '@endorphin/bootstrap';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import * as firebase from 'firebase';

import requestedLocale from './locale';

if (environment.production) {
    enableProdMode();
}

firebase.initializeApp(environment.firebase);

const a = _.now();
bootstrap(AppModule, requestedLocale(), 'cs').then(() => {
    console.log(_.now() - a);
});

