import { Injectable } from '@angular/core';
import { NgRedux } from '@angular-redux/store';
import { ReduxStore } from '@endorphin/redux/store';

export interface ApplicationStore {
    firebase: {
        breeds: {
            [key: string]: {
                editedAt: Date;
                count: number;
            };
        };
    };
}


@Injectable()
export class AppStore extends ReduxStore<ApplicationStore>  {
    constructor(
        private gRedux: NgRedux<any>
    ) {
        super(gRedux);
    }
}
