import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { IndexPageComponent } from '../routes/index/index-page.component';
import { GdprOutagePageComponent } from '../routes/gdpr-outage/gdpr-outage-page.component';

const routes: Routes = [
    {
        path: '**',
        component: GdprOutagePageComponent,
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
