import { NgModule } from '@angular/core';

import { NgReduxModule, NgRedux, DevToolsExtension } from '@angular-redux/store';
import thunk from 'redux-thunk';
import { combineReducers } from 'redux';
import invariant from 'redux-immutable-state-invariant';

import databaseReducer from '@endorphin/firebase/database-reducer';
import staticsReducer from 'store/statics-reducer';
import * as StaticsActions from 'store/statics-actions';

import { AppStore } from './store';

@NgModule({
    imports: [
        NgReduxModule,
    ],
    declarations: [
    ],
    providers: [
        AppStore,
    ],
})
export class StoreModule {
    constructor(
        store: NgRedux<{}>,
        devTools: DevToolsExtension
    ) {
        store.configureStore(
            combineReducers({
                database: databaseReducer,
                statics: staticsReducer,
            }),
            {},
            [ invariant(), thunk ],
            devTools.isEnabled() ? [ devTools.enhancer() ] : []);

        const client = new XMLHttpRequest();
        client.open('GET', '/assets/statics.json');
        client.onload = function () {
            store.dispatch(StaticsActions.laoded(JSON.parse(client.responseText)));
        };
        client.send();
    }
}
