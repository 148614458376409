import * as _ from 'lodash';
import { NgRedux, PathSelector, Comparator, ObservableStore, PropertySelector } from '@angular-redux/store';
import { AnyAction, Reducer, Dispatch, Unsubscribe } from 'redux';
import { Observable } from 'rxjs';
import { map, distinctUntilChanged } from 'rxjs/operators';

export declare type MyFunctionSelector<RootState, S> = ((s: RootState, d?: Dispatch ) => S);
export declare type Selector<RootState, S> =  PropertySelector | PathSelector | MyFunctionSelector<RootState, S>;

export class ReduxStore<S>  {
    constructor( private ngRedux: NgRedux<any>) {}
    dispatch<T extends AnyAction>(action: T): T {
        return this.ngRedux.dispatch(action);
    }
    getState(): S {
        return this.ngRedux.getState();
    }
    subscribe(listener: () => void): Unsubscribe {
        return this.ngRedux.subscribe(listener);
    }
    replaceReducer (nextReducer: Reducer<S, AnyAction>): void {
        return this.ngRedux.replaceReducer(nextReducer);
    }
    select<SelectedType>(selector?: Selector<S, SelectedType>, comparator?: Comparator): Observable<SelectedType> {
        if (selector == null) {
            return this.ngRedux.select();
        }
        return this.ngRedux.select<S>().pipe(
            map( (val) => {
                if (typeof selector === 'function') {
                    return selector(val, this.ngRedux.dispatch);
                } else {
                    return _.get(val, selector);
                }
            }),
            distinctUntilChanged(comparator)
        );
    }
    configureSubStore<SubState>(basePath: PathSelector, localReducer: Reducer<SubState, AnyAction>): ObservableStore<SubState> {
        return this.ngRedux.configureSubStore(basePath, localReducer);
    }

}
