import { Observable } from 'rxjs';
import { NgRedux } from '@angular-redux/store';
import { sync } from './database-actions';

export function onValue<T>(store: NgRedux<any>, path: string[]): Observable<T> {
    store.dispatch<any>(sync(path));
    const selectPath = path.slice();
    selectPath.unshift('database');
    return store.select(selectPath);
}
