import * as _ from 'lodash';
import * as xmljs from 'xml-js';

export default function (xliff: xmljs.ElementCompact, transl: object, lang: string): xmljs.ElementCompact {
    if (xliff == null || transl == null || lang == null) {
        return null;
    }
    const cpy = _.cloneDeep(xliff);
    cpy.xliff.file._attributes['target-language'] = lang;
    _.forEach(cpy.xliff.file.body['trans-unit'], (trn) => {
        const trtar = _.get(transl, trn._attributes.id);
        trn.target = _.cloneDeep(trtar == null ? trn.source : {'_text': trtar});
    });
    return cpy;
}
